body {
  background: #f9f9f9;
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  font-size: 12px;
  line-height: 20px;
}

.text-success {
  color: #43af43 !important;
}

p {
  line-height: 20px;
}

a,
a:link,
a:visited,
a:active {
  text-decoration: none;
  color: #388fc1;
}

a:hover {
  text-decoration: underline;
}

a.menu-item__link:hover {
  text-decoration: none;
}

a.link--unstyled,
a.link--unstyled:link,
a.link--unstyled:visited,
a.link--unstyled:active,
a.link--unstyled:hover {
  color: inherit;
  text-decoration: none;
}